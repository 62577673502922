
interface applyInfoItem {
  name : string;
  phoneNo : string;
  orgName : string;
  dutyName : string;
  demandSituation : string;
  area : string;
}
interface companyImgItem {
  imgUrl ?: string;
  imgId ?: string;
  msgId ?: string;
  title ?: string;
  desc ?: string;
  address ?: string;
  phone ?: string;
  fax ?: string;
  organize ?: string;
  timer ?: string;  
  imgTip1 ?: string;  
  imgTip2 ?: string;  
  imgTwo ?: string;  
  text ?: string;  

}

import companyImg1 from '../assets/ecological-Cooperation/icon_it-company@2x.png'
import companyImg2 from '../assets/ecological-Cooperation/icon_proxy-partner@2x.png'
import companyImg3 from '../assets/ecological-Cooperation/icon_code-plat@2x.png'
  // 公司简介图片
import advantageImg1 from '../assets/CompanyProfile/company-profile1@2x.png'  
import advantageImg2 from '../assets/CompanyProfile/company-profile2@2x.png'  
import advantageImg3 from '../assets/CompanyProfile/company-profile3@2x.png'  
import advantageImg4 from '../assets/CompanyProfile/company-profile4@2x.png'  
import advantageImg5 from '../assets/CompanyProfile/company-profile5@2x.png'  
import advantageImg6 from '../assets/CompanyProfile/company-profile6@3x.png'  
import advantageImg7 from '../assets/CompanyProfile/company-profile7@2x.png'  
import advantageImg8 from '../assets/CompanyProfile/company-profile8@2x.png' 
import cultureImg1 from '../assets/CompanyProfile/company-culture1@2x.png'  
import cultureImg2 from '../assets/CompanyProfile/company-culture2@2x.png'  
import cultureImg3 from '../assets/CompanyProfile/company-culture3@2x.png'  
import cultureImg4 from '../assets/CompanyProfile/company-culture4@2x.png'  
import cultureImg5 from '../assets/CompanyProfile/company-culture5@2x.png'  

// 新闻资讯
import newImg1 from '../assets/NewsInformation/new3@2x.png'
import newImg2 from '../assets/NewsInformation/new1@2x.png'
import newImg3 from '../assets/NewsInformation/new2@2x.png'
import newImg4 from '../assets/NewsInformation/new4@2x.png'
import newImg5 from '../assets/NewsInformation/new5@2x.png'
import newImg6 from '../assets/NewsInformation/new6@2x.png'
// 首页轮播
import banner1 from "../assets/banner/home-banner1.png";
import banner2 from "../assets/banner/home-banner2.png";

// 客户案例
import cusCase1 from "../assets/home/cus-case1.png";
import cusCase2 from "../assets/home/cus-case2.png";
import cusCase3 from "../assets/home/cus-case3.png"
import cusCase4 from "../assets/home/cus-case4.png";
// import banner3 from "../assets/banner/lawer@2x.png";
export const typeImg:companyImgItem[] = [
  {
    imgUrl:companyImg1,
    msgId:"111",
    title:"软件开发公司",
    desc:"为客户提供软件定制开发等相关服务的公司，与移动平台产品进行融合，共同联合打造更专业、更具市场领先性的产品及解决方案。"
  },
  {
    imgUrl:companyImg2,
    msgId:"222",
    title:"代理伙伴",
    desc:"通过自有线上或线下渠道向客户售卖、推广融云产品，并具备向客户提供技术支持及商务咨询能力的合作伙伴。"
  },
  {
    imgUrl:companyImg3,
    msgId:"333",
    title:"低代码开发平台",
    desc:"拥有方便开发者快速开发应用的开发工具，实现双方插件嵌入，共同打造生态圈。"

  },
]
export const cultureImgData:string[] =[cultureImg1,cultureImg2,cultureImg3,cultureImg4,cultureImg5]
export const contactUsList:companyImgItem[] = [
  {
    organize:"北京 BEIJING （中国大陆总部）",
    title:"北京领雁科技股份有限公司总部",
    address:"北京市朝阳区北苑路甲13号1号楼北辰泰岳大厦9层",
    phone:'86-10-52818888 ',
    fax:'+86-10-52818899',
  },
  {
    organize:"上海 SHANGHAI",
    title:"领雁科技上海办事处",
    address:"上海市徐汇区肇嘉浜路1065号甲飞雕国际大厦502",
    phone:'021-64693310',
   
  },
  {
    organize:"广州 GUANGZHOU",
    title:"领雁科技广州软件中心/广州办事处",
    address:"广州市天河区龙口东路342-360号天诚广场裙楼411室",
    phone:'020－38209105',
   
  },
  {
    organize:"武汉 WUHAN",
    title:"领雁科技武汉研发中心",
    address:"湖北省武汉市武昌区积玉桥中山路旭辉大厦706",
    phone:'027-87893358',
   
  },
  {
    organize:"成都 CHENGDU",
    title:"领雁科技成都软件中心/成都办事处",
    address:"成都市武侯区天府大道北段1288号泰达时代中心1号楼1606",
    phone:'028-86764980 ',
   
  },
  {
    organize:"郑州 ZHENGZHOU",
    title:"领雁科技郑州软件中心",
    address:"河南省郑州市郑东新区金水东路49号绿地原盛国际1号楼B座15层180号",
    phone:'0371-55933251',
  },
  {
    organize:"宁波 NINGBO",
    title:"领雁科技宁波软件中心",
    address:"宁波市鄞州区南部商务区大海大厦1206-2",
    phone:'0574-88359069',
   
  },
]
export const advantageItem:companyImgItem[] = [
  {
    imgUrl:advantageImg1,
    title:"20年+",
    desc:"专注服务金融企业"
  },
  {
    imgUrl:advantageImg2,
    title:"260+",
    desc:"金融行业客户"
  },
  {
    imgUrl:advantageImg3,
    title:"90%",
    desc:"技术专业力量"

  },
  {
    imgUrl:advantageImg4,
    title:"1000+",
    desc:"科技金融案例"

  },
  {
    imgUrl:advantageImg5,
    title:"ACE",
    desc:"三大技术平台"
  },
  {
    imgUrl:advantageImg6,
    title:"70+",
    desc:"产品解决方案"

  },
  {
    imgUrl:advantageImg7,
    title:"3",
    desc:"大核心业务线"

  },
  {
    imgUrl:advantageImg8,
    title:"130+",
    desc:"知识产权"

  }
]

export const newMessage :companyImgItem[] = [
  {
    imgUrl:newImg1,
    msgId:"111",
    timer:'2023/05/23',
    title:"复盘才能翻盘 | 北京领雁科技移动金融业务部产品研发部2023年项目复盘交流会",
    desc:"最大的浪费是对经验的浪费，不轻易放过每一段经历，复盘才能翻盘！"
  },
  {
    imgUrl:newImg2,
    msgId:"222",
    timer:'2023/05/22',
    title:"雁启新程·逐梦远航 | 千人齐聚北京，2023领雁科技年会顺利召开！",
    desc:"4月8日，“雁启新程·逐梦远航”领雁科技2023年年会在京盛大举办！本次大会线上线下同步进行，公司核心领导团队与近千名员工欢聚一堂，对公司2022年的工作成果进行总结，并对2023年的提出新的展望。"
  },
  {
    imgUrl:newImg3,
    msgId:"333",
    timer:'2023/05/21',
    title: `纸短情长，感恩来信 | 520来自客户的“告白”——客户感谢信集`,
    desc:"520表白日在这个充满鲜花与浪漫的日子有很多种表达爱的方式来自客户真挚、热切的肯定纷至沓来的感谢信是领雁科技收到的最美情书。"
  },
  {
    imgUrl:newImg4,
    msgId:"333",
    timer:'2023/05/18',
    title:"移动金融成绩单 | 量身定制，实力创新，打造数字时代的智能银行",
    desc:"以业界技术领先的移动开发平台为基础，不断升级打造手机银行、移动展业、移动办公等各类移动化业务产品。使得金融服务更具有安全性、灵活性、普惠性，协助银行打造智能化的生态金融平台。"
  },
  {
    imgUrl:newImg5,
    msgId:"333",
    timer:'2023/04/09',
    title:"领雁科技中标某头部省级农信社合规管理系统建设",
    desc:"近日，凭借专业的轻咨询能力、优质的产品交付能力和创新的产品研发实力，领雁科技中标某头部城商行反洗钱综合管理系统，进一步展现在银行业反洗钱领域的市场优势地位。"
  },
  {
    imgUrl:newImg6,
    msgId:"333",
    timer:'2023/03/27',
    title:"数智专题 | 深挖银行数据智能应用，助推客户深度经营",
    desc:'以数智平台赋能业务应用，围绕数字化风控、数字化运营、数字化营销讲述领雁科技数智平台ESP及系列解决方案，此篇为“数智专题”分享第一篇。'
  },
]
export const bannerData: companyImgItem[] = [
  {
    imgUrl: banner1,
    imgId: "banner1",
    text: "办公管理信息化，线上化。数字化",
  },
  {
    imgUrl: banner2,
    imgId: "banner2",
    text: "安全底座，服务底座，数字底座，助力用户构建一站式协作管理数字化平台",
  },
  // {
  //   imgUrl: banner3,
  //   imgId: "banner3",
  // },
];
export const customerData: companyImgItem[] = [
  {
    imgUrl: cusCase1,
    imgId: "banner1",
    imgTip1:"某股份制银行",
    imgTip2:"数字化协作平台项目",
    imgTwo:'1'
  },
  {
    imgUrl: cusCase2,
    imgId: "banner2",
    imgTip1:"某股份制银行",
    imgTip2:"企业手机银行项目",
    imgTwo:'0'
  },
  {
    imgUrl: cusCase3,
    imgId: "banner3",
    imgTip1:"某股份制银行",
    imgTip2:"电子渠道应用系统项目",
    imgTwo:'1'
  },
  {
    imgUrl: cusCase4,
    imgId: "banner4",
    imgTip1:"某股份制银行",
    imgTip2:"数字化协作平台项目",
    imgTwo:'1'
  },
];